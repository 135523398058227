<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="isLoading">
              <b-row>
                <b-col lg="12" sm="12">
                  <div id="printMe" >
                    <div id="print-area" style="float: left; border: 3px solid #6578B5 !important; box-sizing: border-box; color: rgb(66, 66, 66); height: auto; width: 400px;">
                      <div style="background-color: rgb(110, 13, 143);font-size: 16px; color: #fcfdff;text-align: center !important;padding:0;border: 1px solid #244bca;">
                        <b>‘‘{{ currentLocale === 'en' ? formData.training_title : formData.training_title_bn }}’’ {{ date }}
                                </b>
                      </div>
                      <div style="background-color: rgb(246, 245, 247);font-size: 24px; color: #090a0a;text-align: center !important;padding:0;border: #244bca;">
                        <b>{{ currentLocale === 'en' ? formData.name : formData.name_bn }}</b>
                      </div>
                      <div style="background-color: rgb(246, 245, 247);font-size: 14px; color: #090a0a;text-align: center !important;padding:0;border: #244bca;">
                        <span v-if="formData.designation_id && formData.not_here_designation == 0 && formData.profession_type == 1">{{ getDesignation(formData.designation_id) }}</span>
                        <span v-else>{{ ($i18n.locale=='bn') ? formData.designation_bn : formData.designation_en }}</span>
                      </div>
                      <div style="background-color: rgb(246, 245, 247);font-size: 14px; color: #090a0a;text-align: center !important;padding:0;border: #244bca;">
                        {{ currentLocale === 'en' ? formData.office_en : formData.office_bn }}
                        <span v-if="formData.profession_type != 1 && formData.not_here_office == 0">
                          {{ getOfficeName(formData.professional_office_id) }}
                        </span>
                        <span v-if="formData.profession_type != 1 &&  formData.not_here_office == 1">
                          {{ ($i18n.locale == 'en' ? formData.office_name : formData.office_name_bn) }}<br/>
                        </span>
                        <span v-if="formData.profession_type == 1 && formData.not_here_office == 1">
                          {{ ($i18n.locale == 'en' ? formData.office_name : formData.office_name_bn) }}<br/>
                        </span>
                      </div>
                      <div style="background-color: rgb(246, 245, 247);font-size: 14px; color: #090a0a;text-align: center !important;padding:0;border: #244bca;" class="mb-3">
                        {{ currentLocale === 'en' ? formData.address : formData.address_bn }}
                      </div>
                      <div style="float: left; width: 14%;background-color: green; height: 50px;color: #fcfdff;">
                        <img :src="trainingElearningServiceBaseUrl + 'storage/' + formData.right_logo" height="40px" width="44px" style="border-radius: 50% !important;" class="m-1">
                      </div>
                      <div style="float: left; width: 86%; height: 50px;font-size: 14px;text-align: center !important; line-height: 25px;letter-spacing:-.3px;background-color: green;color: #fcfdff;" v-if="formData.org_id == 12">
                          <b>
                            {{ currentLocale === 'en' ? 'National Agriculture Training Academy (NATA)' : 'জাতীয় কৃষি প্রশিক্ষণ একাডেমি (নাটা)' }} <br>
                            {{ currentLocale === 'en' ? 'Gazipur' : ' গাজীপুর' }}
                            </b>
                      </div>
                      <div style="float: left; width: 86%; height: 50px;font-size: 14px;text-align: center !important; line-height: 25px;letter-spacing:-.3px;background-color: green;color: #fcfdff;" v-if="formData.org_id != 12">
                          <b>
                            {{ currentLocale === 'en' ? formData.org : formData.org_bn }} <br>
                            {{ currentLocale === 'en' ? formData.org_address : formData.org_address_bn }}
                            </b>
                      </div>
                      <!-- <table style="width: 100%; margin-top: 10px;">
                        <tbody>
                          <tr style="background-color: rgb(110, 13, 143);">
                            <td colspan="2" style="text-align: center !important;padding:0">
                              <div style="font-size: 16px; color: #fcfdff;">
                                <b>‘{{ currentLocale === 'en' ? formData.training_title : formData.training_title_bn }}’
                                </b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" style="text-align: center !important;padding:0">
                              <div style="font-size: 13px;">
                                <b>{{ date }}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" style="text-align: center !important;height: 101px;">
                              <div style="color: #E74E16;margin-bottom: 0px;font-size: 16px;padding: 0px;margin-top: 22px;line-height: 20px;font-weight: bold;">{{ currentLocale === 'en' ? formData.name : formData.name_bn }}</div>
                              <div style="color: #7EC565; font-size: 14px; padding: 0px; line-height: 22px;">{{ currentLocale === 'en' ? formData.designation : formData.designation_bn }}</div>
                              <div style="color: #7EC565; font-size: 14px; padding: 0px; line-height: 22px;margin-bottom: 31px;">{{ currentLocale === 'en' ? formData.office_en : formData.office_bn }}, {{ currentLocale === 'en' ? formData.address : formData.address_bn }}</div>
                              <br>
                            </td>
                          </tr>
                          <tr style="background-color: rgb(173, 177, 177);">
                            <td colspan="2">
                              <div style="float: left; width: 8%;">
                                <img :src="trainingElearningServiceBaseUrl + 'storage/' + formData.right_logo" height="22px" width="22px" style="border-radius: 50% !important;">
                              </div>
                              <div style="float: left; width: 92%;">
                                <div style="font-size: 8px; line-height: 25px;letter-spacing:-.3px;">
                                  <b>
                                    {{ currentLocale === 'en' ? formData.org : formData.org_bn }},
                                    {{ currentLocale === 'en' ? formData.org_address : formData.org_address_bn }}
                                    </b>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table> -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="button" variant="primary" class="mr-2" @click="printID">{{ $t('globalTrans.print') }}</b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
          <!-- <pre>{{formData}}</pre> -->
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import VueHtmlToPaper from 'vue-html-to-paper'
import Vue from 'vue'

const options = {
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=no'
  ],
  autoClose: true
}
Vue.use(VueHtmlToPaper, options)

export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      const startDate = this.formData.training_start_date.split('-')
      const endDate = this.formData.training_end_date.split('-')
      let startMonthId = ''
      let endMonthId = ''
      startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
      endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
      if (startDate[1] === endDate[1]) {
        this.date = this.$n(startDate[2]) + ' - ' + this.$n(endDate[2]) + ' ' + (this.currentLocale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ' ' + this.$n(startDate[0], { useGrouping: false })
      } else {
        this.date = this.$n(startDate[2]) + ' ' + (this.currentLocale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ' - ' + this.$n(endDate[2]) + ' ' + (this.currentLocale === 'en' ? endMonthId.text_en : endMonthId.text_bn) + ' ' + this.$n(startDate[0], { useGrouping: false })
      }
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {},
      date: '',
      isLoading: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    designationList () {
      return this.$store.state.commonObj.designationList.filter(item => item.status === 0)
    }
  },
  watch: {
  },
  methods: {
    getOrgName (id) {
      const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getOfficeName (id) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    getDesignation (id) {
      const designation = this.$store.state.commonObj.masterDesignationList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return designation !== undefined ? designation.text_bn : ''
      } else {
        return designation !== undefined ? designation.text_en : ''
      }
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    printID () {
      this.$htmlToPaper('printMe')
    }
  }
}
</script>
<style>
@import url(//db.onlinewebfonts.com/c/46aebfd06fd610b5813d2d877dc23398?family=Tilda+Script);
@import url(//db.onlinewebfonts.com/c/09400bc4b89c1605f1ccd16c0c305a8c?family=Lateef);
@import url(//db.onlinewebfonts.com/c/6d36b202b654e70a446767b843911eea?family=Sexything);
</style>
